@use "../mixins/effects";
@use "../mixins/positioning";
@use "../mixins/texts";
@use "../mixins/outline";
@use "inputs";

@mixin box($border,$shadow,$radius) {
  border: var(--grey-sur) #{$border}px solid;
  box-shadow: 0 0 #{$shadow * 3}px #{$shadow * 3}px rgba(0, 0, 0, 0.1);
  border-radius: #{$radius * 5}px;
  background: var(--white);
}

@mixin sur {
  background: var(--white);

  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 0 20px 0 rgba(0, 0, 0, 0.2);
  //box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 1px, rgb(0 0 0 / 20%) 0px 0px 20px 0px;
  border-radius: 14px;
}

@mixin white {
  background: var(--white);
  box-shadow: 2px 4px 12px rgb(0 0 0 / 10%);
  border-radius: 14px;
}

@mixin closable {
  @include sur;

  .headTop {
    position: relative;
    @include positioning.clear;

    .name {
      font-size: 18px;
      float: left;

      @include texts.font("bold");
    }

    .round {
      height: 30px;
      width: 30px;
      margin-top: -2px;
      border-radius: 100%;
      transition: 0.1s;
      float: right;

      @include effects.hover-alpha;
      @include positioning.flex-globally;

      .icon {
        height: 12px;
        width: 12px;
      }
    }
  }
}

@mixin blur {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .01), 0 0 20px 0 rgba(0, 0, 0, .2);
  border-radius: 15px;
  background: var(--white);
}

@mixin blur-inside {
  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    position: absolute;
    background: var(--white);
  }
}

@mixin card {
  border: solid 1px var(--grey-sur);
  border-radius: 12px;
  padding: 15px;
  transition: 0.05s;


  .topSide {
    @include positioning.clear;
  }

  .leftSide {
    width: calc(100% - 85px);
    float: left;

    .title {
      width: 150px;
      font-size: 17px;
      margin-bottom: 10px;

      @include texts.font("bold");
    }

    .description {
      font-size: 14px;
      width: 180px;
      color: var(--grey-text);
    }
  }

  .rightSide {
    float: right;

    .imageContainer {
      height: 82px;
      width: 82px;
      margin-top: 12px;
      position: relative;
    }
  }

  .bottomSide {
    height: 4px;
    border-top: var(--grey-sur) 1px solid;
    left: -15px;
    width: 100%;
    margin-top: 18px;
    padding: 15px;
    font-size: 13px;
    position: relative;
    color: var(--blue-pulp);
    @include texts.font("semi-bold");
  }
}

@mixin card-form {
  width: 100%;
  border-radius: 12px;
  border: var(--grey-sur) 1px solid;

  .line {
    height: 48px;
    border-bottom: var(--grey-sur) 1px solid;
    width: 100%;
    position: relative;
    @include positioning.flex-vertically;
    @include positioning.clear;

    &:last-child {
      border-bottom: 0;
    }

    &.borderTop {
      border-top: var(--grey-sur) 1px solid;
    }

    &.description {
      height: 30px;
      margin-top: -10px;
    }

    .label {
      font-size: 11px;
      width: 80px;
      margin-left: 18px;
      text-transform: uppercase;
      float: left;

      @include texts.font("bold");
    }

    .editIcon {
      right: 15px;
      position: absolute;

      .icon {
        height: 10px;
        width: 10px;


      }
    }

    input {
      @include inputs.basic;

      width: calc(100% - 122px) !important;
      float: left;
      padding-left: 0;
      height: calc(100% - 2px);
      padding-top: 2px;
      border: 0 !important;
      border-radius: 0;
      background: transparent;
      font-size: 14px !important;
      border-bottom: 2px solid transparent !important;

      &:focus {
        border-bottom: 2px solid var(--blue) !important;
        border-radius: 0;
      }
    }

    .block {
      position: relative;
      width: calc(100% - 98px);
      float: left;
      height: 100%;

      .list {
        height: 36px;
        margin-top: 6px;
        position: relative;

        &.noBorder {
          div[data-selectbox] {
            border: none !important;
          }
        }
      }
    }

    .two, .one {
      float: left;
      position: relative;
      width: calc(50% - 1px);
      height: 100%;

      @include positioning.flex-vertically;

      &:first-child {
        border-right: 1px solid var(--grey-sur);
      }

      &.description {
        left: 18px;
        font-size: 12px;
        color: var(--grey-text);

        &:first-child {
          border: unset;
        }
      }
    }

    .one {
      width: 100%;
      border-right: unset !important;
    }
  }
}