@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentTwoFactorMail {
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;

  .drawing {
    width: 137px;
    height: 137px;

    @include icons.ico("3d-invitation");
  }

  .text {
    text-align: center;
    font-size: 18px;

    @include texts.font("semi-bold");

    span {
      color: var(--red);
    }
  }

  .smallText {
    text-align: center;
    font-size: 13px;
    color: var(--gray);
  }

  .codeContainer {
    width: 360px;
    height: 50px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-right: 38px;

    .retry {
      font-size: 13px;
      margin-right: auto;
      width: 140px;
      margin-left: 40px;
      left: 0;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}