@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/extends/animations";

.staticMobileNavigation {
  .navigation {
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--white);
    height: 58px;
    width: 100%;
    z-index: 99;
    box-shadow: 0px -3px 5px rgb(0 0 0 / 4%);
    margin-bottom: env(safe-area-inset-bottom);

    .case {
      height: 100%;
      //width: 20%;
      width: 100%;
      float: left;
      position: relative;

      @include animations.ripple;

      &.selected {
        .icon {
          filter: var(--icon-blue-mobile) !important;
        }

        .name {
          color: var(--blue-mobile);
        }
      }

      &.home {
        .icon {
          @include icons.ico("black-logo");
        }
      }

      &.blog {
        .icon {
          @include icons.ico("black-blog");
        }
      }

      &.inbox {
        .icon {
          @include icons.ico("black-inbox");
        }
      }

      &.publisher {
        .icon {
          @include icons.ico("black-publisher");
        }
      }

      &.statistics {
        .icon {
          @include icons.ico("black-statistics");
        }
      }

      &.contacts {
        .icon {
          @include icons.ico("black-user");
        }
      }

      .iconContainer {
        width: 100%;
        margin-top: 12px;
        margin-bottom: 3px;

        @include positioning.flex-globally;

        .icon {
          height: 19px;
          width: 19px;
          filter: var(--icon-grey-mobile);
        }
      }

      .name {
        font-size: 12px;
        color: var(--grey-mobile);
        width: 100%;
        text-align: center;

        @include texts.font("semi-bold");
      }

      .notification {
        background: var(--red);
        position: absolute;
        font-size: 9px;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        border: 3px solid var(--white);
        color: var(--white);
        top: 6px;
        right: calc(50% - 21px);


        @include texts.font("semi-bold");
        @include positioning.flex-globally;
      }

      .entity {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          height: 32px;
          width: 32px;
          position: relative;
        }
      }
    }
  }
}