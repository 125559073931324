@use "../../../../../sedestral/sass/icons/icons";
@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/mixins/positioning";

.globalSelectboxTooltip {
  position: relative;
  overflow: hidden;

  .searchContainer {
    display: flex;
    align-items: center;

    margin-bottom: 10px;
    margin-top: 5px;

    @include positioning.clear;

    .icon {
      margin-right: 10px;
      filter: var(--icon-grey-dark);
      height: 14px;
      width: 14px;
      float: left;
      margin-left: 4px;
      margin-top: 2px;

      @include icons.ico("black-search");
    }


    .input {
      padding: 5px;
      float: left;
      font-size: 13px;
      border: unset !important;
      width: calc(100% - 20px);

      @include texts.font("semi-bold");
    }

  }

  .casesScrollable {
    max-height: 100%;

    &[scrollvirtual] {
      .case {
        width: calc(100% - 18px) !important;
      }

      div[scrolltrack] {
        right: 0 !important;
        top: 8px;
        height: calc(100% - 16px);
      }
    }

    .casesContainer {
      .case {
        margin-bottom: 2px;
        width: calc(100% - 10px);
        font-size: 14px;
        border-radius: 8px;
        cursor: pointer;
        padding: 5px 5px;
        color: var(--black);

        @include positioning.flex-vertically;
        @include texts.font("semi-bold");

        &:hover {
          background: var(--grey-sur);
        }

        &.selected {
          background: var(--grey-sur);

          &:hover {
            background: var(--grey-sur-hover);
          }
        }

        &.desc {
          display: block;
        }

        .description {
          font-size: 13px;
          margin-top: 10px;
          color: var(--grey-text-dark);

          @include texts.font("medium");
        }

        div[buttonIcon] {
          height: 16px;
          width: 16px;
          margin-left: 0;
          margin-top: 1px;
          margin-right: 6px;
          float: left;
          position: relative;
        }

        div[buttonAvatar] {
          height: 18px;
          width: 18px;
          float: left;
          border-radius: 100%;
          margin-right: 6px;
          background: var(--grey-sur);
          position: relative;
        }

        div[buttonColor] {
          height: 14px;
          width: 14px;
          float: left;
          border-radius: 3px;
          margin-top: 1px;
          margin-right: 6px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .case {
      width: calc(100% - 18px) !important;
      font-size: 16px !important;
      padding: 9px 9px !important;
    }
  }
}