@use "../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../sedestral/sass/extends/buttons";
@use "../../../../../sedestral/sass/mixins/texts";

.globalButtonsSave {
  @include positioning.clear;

  &.black {
    .saveButton {
      background: var(--black) !important;
      color: var(--white) !important;
      border-radius: 55px;

      &:hover {
        opacity: 0.7;
      }
    }

    .cancelButton {
      border-radius: 55px;
    }
  }

  .saveButton {
    @include buttons.green;

    float: right;
    border-radius: 10px;
    font-size: 14px;
    padding: 14px 25px;
    position: relative;
    transition: 0s;

    &.inactive {
      filter: grayscale(1);
    }

    svg {
      width: 18px !important;
      height: 18px !important;

      circle {
        stroke-width: 5px !important;
        stroke: var(--white) !important;
      }
    }

    @include texts.font("semi-bold");
  }

  .cancelButton {
    @include texts.font("semi-bold");

    float: right;
    border-radius: 10px;
    font-size: 14px;
    margin-right: 10px;
    position: relative;
    padding: 14px 25px;
    background: var(--grey-sur);
    cursor: pointer;

    &:hover {
      border-color: transparent !important;
      background: var(--grey-sur-hover);
    }

    svg {
      width: 18px !important;
      height: 18px !important;

      circle {
        stroke-width: 5px !important;
      }
    }
  }
}