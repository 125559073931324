@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentOfferGrid {
  border: 1px solid var(--grey-sur);
  width: 100%;
  border-radius: 10px;

  .line {
    display: flex;

    &:last-child {
      .entry {
        border-bottom: unset;
      }
    }

    .entry {
      width: 100%;
      padding: 15px;
      border-right: 1px solid var(--grey-sur);
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-bottom: 1px solid var(--grey-sur);
      gap: 5px;

      &:last-child {
        border-right: unset;
      }

      &:first-child {
        min-width: 370px;
      }

      .title {
        font-size: 15px;

        @include texts.font("semi-bold");
      }

      .description {
        font-size: 13px;
        color: var(--grey-text-very-dark);
      }

      .icons {
        display: flex;
        gap: 8px;

        .descIcon {
          width: 24px;
          height: 24px;

          &.shopify {
            @include icons.ico("colored-shopify");
          }

          &.wordpress {
            @include icons.ico("colored-wordpress");
          }

          &.wix {
            @include icons.ico("black-wix");
          }

          &.prestashop {
            @include icons.ico("colored-prestashop");
          }
        }
      }

      .value {
        font-size: 14px;
        word-break: break-word;

        span {
          @include texts.font("semi-bold");
        }
      }

      .boolTrue {
        height: 20px;
        width: 20px;
        background: var(--black);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        .icon {
          height: 10px;
          width: 10px;
          filter: var(--icon-white);

          @include icons.ico("black-checked");
        }
      }

      .boolFalse {
        height: 4px;
        width: 20px;
        background: var(--grey-dark);
        border-radius: 2px;
      }
    }
  }


  @media screen and (max-width: 1100px) {
    .line {
      .entry {
        &:first-child {
          min-width: 200px !important;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .line {
      &:first-child {
        display: block;
      }

      &:not(:first-child) {
        display: none;
      }

      .entry {
        margin-bottom: 25px;
        width: unset;
        border-right: unset;

        &:first-child {
          display: none;
        }
      }
    }
  }
}