.globalButtonCheckBox {
  border: 2px solid var(--grey-sur);
  padding: 3px;
  border-radius: 100%;
  position: relative;
  width: fit-content;
  cursor: pointer;

  .caret {
    height: 8px;
    width: 8px;
    background: var(--white);
    border-radius: 100%;
  }

  &[unchecked] {
    &:hover {
      .caret {
        background: var(--grey-sur);
      }
    }
  }

  &[checked] {
    border-color: transparent;
    background: var(--green);
  }
}