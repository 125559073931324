@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentOfferBlocked {
  .description {
    margin-top: 20px;
    font-size: 14px;
  }

  .progress {
    border-radius: 3px;
    height: 6px;
    position: relative;
    overflow: hidden;
    margin-top: 15px;
    width: 70%;
  }

  .button {
    background: var(--green-dark);
    color: var(--white);
    cursor: pointer;
    border-radius: 9px;
    margin-top: 20px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: fit-content;
    margin-left: auto;
    padding: 0 16px;
  }

  .icon {
    height: 100px;
    width: 100px;

    @include icons.ico("3d-offer-list");
  }
}