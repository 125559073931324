@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";

.componentOfferTemplate {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  position: relative;

  &.selected {
    .selectedCheck {
      display: block;
    }

    .button {
      @include outline.black(2);
    }
  }

  .selectedCheck {
    position: absolute;
    right: 0;
    display: none;
    height: 10px;
    width: 10px;
    filter: var(--icon-black);

    @include icons.ico("black-checked");
  }

  .popular {
    padding: 5px;
    background: var(--black);
    position: absolute;
    top: -32px;
    width: 80px;
    left: calc(50% - 40px);
    border-radius: 25px;
    color: var(--white);
    font-size: 13px;
    text-align: center;
  }

  .name {
    font-size: 14px;

    @include texts.font("semi-bold");
  }

  .icon {
    width: 30px;
    height: 30px;
    position: relative;
  }

  .price {
    font-size: 20px;

    @include texts.font("bold");

    span {
      font-size: 14px;

      @include texts.font("semi-bold");
    }
  }

  .description {
    font-size: 14px;
  }

  .button {
    margin-top: auto;
    color: var(--white);
    background: var(--black);
    border-radius: 30px;
    padding: 10px 12px;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid var(--white);

    @include texts.font("semi-bold");

    &:hover {
      opacity: 0.7;
    }
  }

  .content {
    display: none;
    flex-direction: column;
    gap: 12px;

    .entry {
      border-bottom: 1px solid var(--grey-sur);
      padding-bottom: 7px;

      .title {
        font-size: 15px;
        display: flex;
        gap: 8px;
        align-items: center;

        @include texts.font("semi-bold");

        .boolTrue {
          height: 20px;
          width: 20px;
          background: var(--black);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;

          .icon {
            height: 10px;
            width: 10px;
            filter: var(--icon-white);

            @include icons.ico("black-checked");
          }
        }

        .boolFalse {
          height: 4px;
          width: 20px;
          background: var(--grey-dark);
          border-radius: 2px;
        }
      }

      .description {
        font-size: 13px;
        color: var(--grey-text-very-dark);
      }

      .icons {
        display: flex;
        gap: 8px;
        margin-left: 26px;
        margin-top: 5px;

        .descIcon {
          width: 20px;
          height: 20px;

          &.shopify {
            @include icons.ico("colored-shopify");
          }

          &.wordpress {
            @include icons.ico("colored-wordpress");
          }

          &.wix {
            @include icons.ico("black-wix");
          }

          &.prestashop {
            @include icons.ico("colored-prestashop");
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {

    .content {
      display: flex;
    }

    .button {
      width: fit-content;
    }
  }
}