@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentOfferSolution {
  display: flex;
  height: 100%;
  align-items: center;

  .name {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 16px;

    @include texts.font("semi-bold");
  }

  .cursor {
    margin-left: auto;
    display: flex;
    margin-right: 15px;

    .round {
      height: 28px;
      width: 28px;
      background: var(--grey-sur);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;

      &:hover {
        background: var(--grey-sur-hover);
      }

      &.less {
        .icon {
          @include icons.ico("black-less");
        }
      }

      &.plus {
        .icon {
          @include icons.ico("black-plus");
        }
      }

      .icon {
        height: 12px;
        width: 12px;
      }
    }

    .input {
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 18px;
      border: unset;
      width: 50px;
      text-align: center;

      @include texts.font("semi-bold");
    }
  }
}