@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/extends/inputs";

:global {
  body {
    @include texts.font("medium");
    background: var(--white);
    margin: 0;
    color: var(--black);
    padding: 0;
    overflow: hidden;

    &.mobile {
      main {
        left: 0px !important;
        min-width: unset;
        width: 100% !important;
        background: unset;
      }
    }

    &.navigation {
      main {
        left: 61px;
        width: calc(100% - 61px);
      }
    }
  }

  main {
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 100%;
    min-width: 970px;

  }

  input {
    @include inputs.border;
  }

  hr {
    border: none;
    border-bottom: 1px solid rgb(230, 230, 230);
  }

  button {
    @include texts.font("medium");
    cursor: pointer;
    outline: none;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }

  div[data-placeholder] {
    &.ql-blank {
      &:before {
        content: attr(data-placeholder);
        display: block;
        position: absolute;
        color: var(--grey-text);
      }
    }
  }

  div[contenteditable=true] {
    outline: none;
    display: inline-block;
    cursor: text;

    &:empty:before {
      content: attr(placeholder);
      display: block;
      color: var(--grey-text);
    }
  }

  ::-moz-selection {
    color: var(--black) !important;
    background: var(--blue-selection) !important;
    text-decoration-color: var(--black) !important;
  }

  ::selection {
    background: var(--blue-selection) !important;

  }

  .disableSelection {
    ::-moz-selection {
      color: transparent !important;
      background: transparent !important;
      text-decoration-color: var(--black) !important;
    }

    ::selection {
      background: transparent !important;

    }
  }

  .grecaptcha-badge {
    opacity: 0;
  }

  [data-s="launcher"] {
    z-index: 2 !important;
  }
}
