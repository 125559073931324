@use "../../../../../sedestral/sass/mixins/keyframes";

.globalLoaderSliding {
  left: 0;
  visibility: visible;
  width: 100%;
  z-index: 999;
  background: var(--grey-alpha);
  top: 0;
  position: fixed;

  .bar {
    @include keyframes.slide;
    height: 4px;
    position: absolute;
    width: 25%;
    background: var(--black-alpha-middle);
    top: 0;
    animation: slide 1s infinite;;
  }
}