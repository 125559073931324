@use "src/sedestral/sass/icons/icons";

.componentOfferTemplateIcon {
  height: 100%;
  width: 100%;

  &.basic {
    @include icons.ico("3d-offer-basic");
  }

  &.medium {
    @include icons.ico("3d-offer-medium");
  }

  &.pro {
    @include icons.ico("3d-offer-pro");
  }
}