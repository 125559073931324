@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";

.componentOffer {
  height: 100%;
  overflow: auto;
  width: calc(100% - 1px);
  padding-right: 2px;
  color: var(--black);

  &.website {
    .brand {
      display: none;
    }
  }

  &.modal {
    .content {
      margin-bottom: 100px;
    }

    .price {
      visibility: visible;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: var(--white);
      margin-bottom: 0;
      padding-bottom: 15px;
    }
  }

  div[scrolltrack] {
    right: -14px;
    height: calc(100% - 20px);
  }

  .loader {
    padding: 20px;
    height: calc(100% - 40px);
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    margin-top: 10px;

    .brand {
      font-size: 20px;
      width: 480px;
      text-align: center;
      background: -webkit-linear-gradient(#000, #606060);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include texts.font("bold");
    }

    .period {
      height: 26px;
      border: 3px solid var(--grey-sur);
      padding: 4px;
      border-radius: 14px;
      width: 240px;

      div[chentry] {
        font-size: 14px;

        @include texts.font("semi-bold");
      }
    }

    .promo {
      font-size: 12px;
      padding: 6px 10px;
      background: var(--black);
      color: var(--white);
      border-radius: 25px;
    }
  }

  .templates {
    display: flex;
    gap: 30px;
    padding: 3px;

    .container {
      position: relative;
      border-radius: 25px;
      background: var(--grey-sur);
      padding: 15px 0px;
      cursor: pointer;
      width: 33.33%;

      @include outline.blue-hover(3);

      &.selected {
        @include outline.outline(3);
      }
    }
  }

  .solutions {
    margin-top: 20px;
    margin-bottom: 20px;

    .title {
      font-size: 14px;
      margin-bottom: 15px;

      @include texts.font("semi-bold");
    }

    .solutionsContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .solution {
        height: 40px;
        border: 1px solid var(--grey-sur);
        border-radius: 12px;
        width: 100%;
      }
    }
  }

  .content {
    margin-top: 20px;
    padding: 12px;
    border: 1px solid var(--grey-sur);
    border-radius: 12px;
    margin-bottom: 25px;
  }

  .price {
    margin-bottom: 20px;
    border-top: 1px solid var(--grey-sur);
    padding-top: 15px;
  }

  @media screen and (max-width: 480px) {
    .templates {
      flex-direction: column;

      .container {
        width: 100%;
      }
    }
  }
}