@use "src/sedestral/sass/icons/icons";

.componentCapsuleIcon {
  height: 100%;
  width: 100%;

  &.inbox, &.inboxGrey {
    @include icons.ico("black-inbox");
  }

  &.admin {
    @include icons.ico("black-logo");
  }

  &.sell {
    @include icons.ico("black-sell");
  }

  &.blog {
    @include icons.ico("black-blog");
  }

  &.statistics {
    @include icons.ico("black-statistics");
  }

  &.channels {
    @include icons.ico("black-channels");
  }

  &.clientService {
    @include icons.ico("black-help");
  }

  &.publisher, &.publisherGrey {
    @include icons.ico("black-publisher");
  }

  &.contacts, &.contactsGrey {
    @include icons.ico("black-user");
  }

  &.chatBot {
    @include icons.ico("black-bot");
  }

  &.mails {
    @include icons.ico("black-email");
  }

  &.livechat {
    @include icons.ico("black-livechat");
  }

  &.engagement {
    @include icons.ico("black-mail");
  }

  &.advertising {
    @include icons.ico("black-marketing");
  }

  &.mailling {
    @include icons.ico("black-mailling");
  }

  &.medias {
    @include icons.ico("black-media");
  }


  &.calendar {
    @include icons.ico("black-calendar");
  }

  &.documents {
    @include icons.ico("black-locker");
  }

  &.products {
    @include icons.ico("black-box");
  }

  &.estimate {
    @include icons.ico("black-estimate");
  }

  &.sedestral, &.general {
    @include icons.ico("black-logo");
  }

  &.helpCenter {
    @include icons.ico("black-help-center");
  }

  &.document {
    @include icons.ico("black-document");
  }
}