@use "./src/sedestral/sass/mixins/texts";
@use "./src/sedestral/sass/mixins/outline";

.componentVisualInputStates {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;

  @include texts.font("medium");

  &.focused {
    .title {
      font-size: 14px;
      padding: 0 5px !important;
      top: -11px;
      background: var(--white);
      height: 22px;
      z-index: 2;
      left: 10px;
      position: absolute;

      @include texts.font("semi-bold");
    }
  }

  &.error {
    .input {
      box-shadow: 0 0 0 3px var(--red-light) !important;
      border-color: var(--red) !important;
    }

    .title {
      color: var(--red) !important;
    }
  }

  &.bold {
    .input {
      @include texts.font("bold");
    }
  }

  &.semiBold {
    .input {
      @include texts.font("semi-bold");
    }
  }

  &.outline {
    .input:focus {
      box-shadow: var(--outline-blue) 0 0 0 2px;
    }
  }

  &.noBorder {
    .input {
      border-width: 1px;
      border-color: transparent;

      &:focus {
        border-color: transparent;
      }
    }
  }

  .select {
    height: 100%;
    display: flex;
    align-items: center;
    width: 80px;

    div[data-selectbox] {
      height: calc(100% - 8px);
      margin-right: 10px;
    }
  }

  .title {
    position: absolute;
    border-radius: 5px;
    top: 0;
    height: 100%;
    pointer-events: none;
    transition: 0.2s;
    display: flex;
    z-index: 0;
    align-items: center;
    justify-content: center;
    color: var(--black);

    &.noLabel {
      display: none !important;
    }
  }

  .input {
    height: 100%;
    background: transparent;
    padding: 0;
    z-index: 1;
    border-style: solid;
    border-color: var(--grey-sur);
    border-width: 2px;

    &:focus {
      border-color: var(--grey-sur-hover);
    }
  }
}