@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/icons/icons";

.componentCountryPicker {
  width: 100%;

  .countryIcon {
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }
}