@use "src/sedestral/sass/mixins/texts";

.componentOfferPrice {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;

  .names {
    margin-right: 30px;

    .title {
      font-size: 14px;

      @include texts.font("semi-bold");
    }

    .pricing {
      @include texts.font("bold");

      .price {
        font-size: 28px;
      }

      .period {
        font-size: 16px;
      }
    }
  }

  .button {
    background: var(--offer-free);
    padding: 14px 20px;
    height: fit-content;
    border-radius: 12px;
    color: var(--white);
    cursor: pointer;
    position: relative;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--offer-free-hover);
    }

    &.loading {
      color: transparent;
    }
  }
}